<template>
  <v-container v-if="user.loggedIn">
    <v-row justify="center" class="text-center mt-4">
      <v-col cols="12" md="10" lg="9">
        <v-avatar size="75"><v-img :src="user.person.avatar"></v-img></v-avatar>
      </v-col>
      <v-col cols="12" md="5" lg="8">
        <v-card color="blue-grey lighten-4" class="rounded-xl pa-4">
          <v-row>
            <v-col cols="12">
              <h3 class="text-left">Namen ändern</h3>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field filled rounded hide-details v-model="user.data.vorname" label="Vorname"></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field filled rounded hide-details v-model="user.data.nachname" label="Nachname"></v-text-field>
            </v-col>
            <v-col cols="12" class="text-center">
              <v-btn rounded color="success" @click="save_name()">Speichern</v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="12" md="10" lg="8" v-if="false">
        <v-card color="blue-grey lighten-4" class="rounded-xl pa-4">
          <v-row>
            <v-col cols="12">
              <h3 class="text-left">E-Mail Adresse ändern</h3>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field filled rounded hide-details v-model="email.neu" label="Neue E-Mail Adresse"></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field filled rounded hide-details v-model="email.neu_bestaetigen" label="Neue E-Mail Adresse bestätigen"></v-text-field>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="12" md="10" lg="8" v-if="false">
        <v-card color="blue-grey lighten-4" class="rounded-xl pa-4">
          <v-row>
            <v-col cols="12">
              <h3 class="text-left">Passwort ändern</h3>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field filled rounded hide-details v-model="passwort.alt" label="Bisheriges Passwort"></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field filled rounded hide-details v-model="passwort.neu" label="Neues Passwort"></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field filled rounded hide-details v-model="passwort.neu_bestaetigen" label="Neues Passwort bestätigen"></v-text-field>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import logout from '../../../auth/auth'
import {
  doc,
  updateDoc
} from 'firebase/firestore'


export default {
  name: 'MeinProfil',
  data(){
    return {
      passwort: {
        alt: '',
        neu: '',
        neu_bestaetigen: ''
      },
      email: {
        neu: '',
        neu_bestaetigen: ''
      }
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
      vereine: 'vereine',
      db: 'db',
      sportarten: 'sportarten',
      sportangebote: 'sportangebote',

    }),
  },
  methods: {
    logout() {
      logout.logout(this.auth)
    },
    async save_name(){
      const userdataRef = doc(this.db, "User", this.user.uid);
      await updateDoc(userdataRef, {
        vorname: this.user.data.vorname,
        nachname: this.user.data.nachname,
      });
    }
  },
}
</script>
